<template>
  <div class="container">

    <div class="formed-pps-teaching-load mt-4 mb-4">
      <h4 class="text-center mb-4">Сформированный расчет нагрузки</h4>

      <div class="row mb-4">
        <div class="col-md-12">
          <button @click="$router.push('disciplines-for-teaching-load')" class="btn btn-primary">Расчет нагрузки</button>


        </div>
      </div>

      <DataTable :value="ppsLoad_form.formedPpsTeachingLoad" :paginator="true" :rows="10" showGridlines
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">
        <Column header="Преподаватель">
          <template #body="{data}">

            <div v-if="data.lastname == null">
              Вакансия
            </div>
            <div v-else>
              <router-link :to="'/individual-pps-teaching-load?pps_id=' + data.pps_id" target="_blank">
                {{data.lastname}} {{data.firstname}} {{data.middlename}}
              </router-link>



            </div>
          </template>
        </Column>


        <Column field="lecture_hour" header="Лекционные часы"></Column>
        <Column field="seminar_hour" header="Семинарские часы"></Column>
        <Column field="office_hour" header="Офисные часы"></Column>
        <Column field="exam_hour" header="ВСК/Экзамен"></Column>
        <Column field="total_hour" header="Итого"></Column>





      </DataTable>




    </div>
  </div>
</template>


<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "FormedPpsTeachingLoad",
  data() {
    return {
      
    }
  },
  computed: {
    ...mapState('ppsLoad', ['ppsLoad_form']),

  },

  methods: {
    ...mapActions('ppsLoad', ['GET_FORMED_PPS_TEACHING_LOAD']),

  },

  async mounted() {
    await this.GET_FORMED_PPS_TEACHING_LOAD()

  }
}
</script>

<style scoped>
</style>